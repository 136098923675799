/* Main styling */

@import "webfontkit-Millow/stylesheet.css";


@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@300;400&family=Rubik+Pixels&family=Young+Serif&display=swap');

.App {
  font-family: 'Handjet', sans-serif;
  text-align: center;
  background-Image: url("./img/TILEvint bg.png");
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

#fancy-text {
  font-family: 'millowregular';
  letter-spacing: 3px;
  line-height: 1.2;
  font-size: 250%;
  padding: 10px;
}

.title-plaque
{
  background-Image: url("./img/marked bg.png");
}


/* FOOTER */
.bottomDweller
{  
  background-Image: url("./img/on-bike.jpg");
  background-repeat:no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 1080px;
  width: 100%;
  border: none;
  /* mask-size: 300px 200px; */
  mask-image: -webkit-gradient(linear, center top, center bottom, color-stop(0.00,  rgba(0,0,0,0)),color-stop(1.00,  rgba(0,0,0,1)));
}

/* main and sidebar */

/* JUKEBOX */
.record-player-container{
  justify-content: center;
  margin: 64px;
  display: flex;
  flex-wrap: wrap;
  
}
.vinyl{

  text-align: center;
  display:inline-block;
}
.music-text{
  font-size: x-large;
}
#rotate {
  width: 100px;
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*  general tings */
.heading{
  padding: 40px;
}



/* Artefact stuffs
.artefact-container {
  position: relative;

} */

.vibrate {
  animation: vibrate 1s ease infinite;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateX(1px);
  }
  75% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(1px);
  }
}
.artefact-heading {
  padding: 1vw; /*padding proportionally to viewport width */
  background-color: rgba(250, 235, 215, 0.533);
  letter-spacing: 3px;
}

.artefact-grid-container {
  display: flex;
  justify-content: center;
  background-color: rgba(250, 235, 215, 0.533);
  padding: 3vw; /*padding proportionally to viewport width */
}

.artefact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
  gap: 1vw;
  justify-items: center;
}

.artefact-item {
  text-align: center;
  margin: 0;
}

/* For mobile */
@media (max-width: 768px) {
  .artefact-heading {
    padding: 4vw; /*padding proportionally to viewport width */
  }
  
  .artefact-grid-container {
    padding: 6vw; /*padding proportionally to viewport width */
  }
  .artefact-grid {
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
    
  }
}

.music-text {
  color:  rgba(54, 54, 54, 0.636);
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-family: 'Handjet', sans-serif;
  
}

/* GALLERY */

/* #fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}
#fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
} */

#img-wrapper {
  margin: auto;
  width: 75%;
  /* padding: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 10px;
 }
 
 /* #img-wrapper > div {
  /* flex: 1 1 33%; 
  /* display: flex; 

 } */
/* desktop */
.gall-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5%;
}

/* mobile */
@media (max-width: 768px) {
  .gall-img {
    width: 30vw; /* image fill the width of its container */
    height: 30vw; /* height adjust proportionally */
  }
}

#collab-container{
  gap: 5px;
  margin: auto;
  width: 75%;
  /* padding: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 10px 10px; */
  border-radius: 5px;
  box-sizing: border-box; /* Include border in the element's total width and height */
  background-color: rgba(250, 235, 215, 0.533); /* Set a pastel color as the background */
}
 .collab-logo{
  width: 175px;
  height: 175px;
  padding: 25px;
  align-items: center;
  object-fit: contain;
}

/* For mobile */
@media (max-width: 768px) {
  .collab-logo {
    width: 25vw; /* width to 30% of the viewport width */
    height: 25vw; /*height to match the width, maintaining a square aspect ratio */
    padding: 3vw;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Skills marquee */
.word-marquee {
  display: inline-block;
  overflow: hidden;
  font-size: xx-large;
  padding: 1vw;
}

.moving-word-transition {
  display: inline-block;
  transition: transform 1.5s ease-in-out;
}

.moving-word {
  margin-right: 10px; /* Adjust the gap size as needed */
  display: inline-block;
  opacity: 0.15;
}

.moving-word.active {
  opacity: 1;
  color: darkgreen;
}

/* For bg parallax images */
.parallax-container {
  position: relative;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
}

.parallax-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-out;
}

.sticky-image {
  position: fixed;
  top: 50px; /* Adjust the value to the desired top position */
  left: 50px; /* Adjust the value to the desired left position */
  transition: transform 0.3s ease; /* Adding smooth transition for movement */

  /* Add any other styling you want */
}
.model-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.model-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* gap: 20px; */
}

.model-container {
  display: flex;
  justify-content: right;
  align-items: center;
}

@import url('https://fonts.googleapis.com/css2?family=Jersey+25+Charted&display=swap');


.text-container {
  font-family: "Jersey 25 Charted", sans-serif;
  color:rgb(0, 0, 0);
  font-weight: 400;
  font-style: normal;
  font-size: 3vw;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: left; 
  width: 325px;
  height: 250px;
}

.model-view {
  width: 500px;
  height: 700px;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: right; 
}

/* Media query for devices with maximum width of 768px (typical for mobile devices) */
@media (max-width: 768px) {
  .text-container {
    font-size: 5vw; /* Adjusted font size for mobile devices */
    width: 15vw;
    height: 30vh;
  }
  .model-view {
    width: 50vw;
    height: 40vh;
  }
}



/* Styling for the scrolling bar container
.scrolling-bar {
  width: 300px;
  max-height: 200px; 
  border: 1px solid #ccc;
  padding: 10px;
}

.scrolling-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scrolling-bar li {
  margin-bottom: 5px;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
} */


/* FIBONACCI */
.fibonacci {
  /* background-color: darkgoldenrod; */
  
  max-width: 50%;
}

/* STANDING FIN */
.standing-fin{
  width: 8%;
  align-self: center;
}


/* SKILLS BIT */
.skills-container{
  padding: 25px;
  height: 400px;
  /* background-Image: url("./img/marked bg.png"); */
  /* border-style: groove;
  border-color: antiquewhite;
  border-radius: 10px;
  border-width: 10px; */

}
.skill-item{
  /* display: inline-block; */
  animation: backnfourth 10s linear infinite alternate;
  text-align: left;

}
.skill-item1{
  /* display: inline-block; */
  animation: backnfourth 11.4s linear infinite alternate;
  text-align: left;
  box-sizing: border-box; 
}
.skill-item2{
  /* display: inline-block; */
  animation: backnfourth 11s linear infinite alternate;
  text-align: left;
  box-sizing: border-box; 
}
.skill-item3{
  /* display: inline-block; */
  animation: backnfourth 12s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item4{
  /* display: inline-block; */
  animation: backnfourth 9s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item5{
  /* display: inline-block; */
  animation: backnfourth 9.5s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item6{
  /* display: inline-block; */
  animation: backnfourth 15s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item7{
  /* display: inline-block; */
  animation: backnfourth 12.1s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item8{
  /* display: inline-block; */
  animation: backnfourth 13.7s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item9{
  /* display: inline-block; */
  animation: backnfourth 11.2s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item10{
  /* display: inline-block; */
  animation: backnfourth 14.2s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
.skill-item11{
  /* display: inline-block; */
  animation: backnfourth 12.3s linear infinite alternate;
  text-align: left;
  box-sizing: border-box;
  
}
@keyframes backnfourth {
  0% { color: red; }
 25% { color: blue; }
 50% { color: green; }
 75% { color: orange; }
100% { transform: translateX(90%); color: purple; }
}

.bubble {
  /* background-color: white; */
  border-radius: 50%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
  height: 152px;
  margin-right: 20px;
  width: 152px;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(54, 54, 54);
  margin: 0;
  padding: 0;
  box-sizing: border-box; 

}

.App-link {
  color:  rgba(54, 54, 54, 0.636);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}