/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 17, 2023 */



@font-face {
    font-family: 'millowregular';
    src: url('millow-webfont.woff2') format('woff2'),
         url('millow-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}